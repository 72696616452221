.landing-main{
    width: 100%;
    height: 90vh
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
}

.text{
    position: absolute;
    top: 117.25px;
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}


.svg-green {
    filter: drop-shadow(0px 0px 16px rgba(66, 255, 3, 1));
}

.svg-yellow {
    filter: drop-shadow(0px 0px 12px rgb(255, 247, 1));
}

.svg-blue {
    filter: drop-shadow(0px 0px 12px rgb(3, 192, 255));
}

.svg-red {
    filter: drop-shadow(0px 0px 12px rgb(255, 32, 3));
}

.nextui-collapse-title-content h3{
    font-size:medium !important;
}


.regcards-main {
    position: relative;
}

.regcards-main:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: url('../../assets/images/ClubLights1.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}

.regcards-bg{
    position: relative;
    border-color: white !important;
    border-width: 5px;
    padding-bottom: 80px;
    margin: 20px 0px 20px 0px
}

.info1-main {
    position: relative;
}

.info1-main:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: url('../../assets/images/ClubLights2 2.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}
  
.info1-bg{
    position: relative;
    border-color: white !important;
    border-width: 5px;
    padding-bottom: 80px;
    margin: 0px 0px 20px 0px
}

.faq-main {
    position: relative;
}

.faq-main:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    background-image: url('../../assets/images/NeonSign1.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}
  
.faq-bg{
    position: relative;
    border-color: white !important;
    border-width: 5px;
    padding-bottom: 80px;
    margin: 00px 0px 20px 0px
}